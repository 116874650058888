import { GridRow } from "./grid-row";
import { FilterItemBoolean, FilterItemDate, FilterItemNumber, FilterItemSelect, FilterItemText } from "./page.model";


export class BaseColDef<T = any> {
  colId?: string;
  field: keyof T;
  headerName?: string;
  sortable?: boolean = true;
  resizable?: boolean = true;
  editable?: boolean = false;
  headerTooltip?: string;
  width?: number;
  /** Min width, in pixels, of the cell */
  minWidth?: number = 150;
  /** Max width, in pixels, of the cell */
  maxWidth?: number;
  /** True if this column should stretch rows height to fit contents */
  autoHeight?: boolean;
  /** Class to use for the cell. Can be string, array of strings, or function. */
  cellClass?: string | string[] | (() => string | string[]);
  hiden?: boolean = false;

  disabled?: boolean | ((item: T, gridRow: GridRow) => boolean);
  onChange?: (item: T, gridRow?: GridRow) => void | Promise<void>;
  onEnter?: (item: T, gridRow?: GridRow) => void | Promise<void>;

}

export class TextColDef<T = any> extends BaseColDef<T> {
  type: "TEXT" = "TEXT";
}

export class NumberColDef<T = any> extends BaseColDef<T> {
  type: "NUMBER" = "NUMBER";
}

export class BooleanColDef<T = any> extends BaseColDef<T> {
  type: "BOOLEAN" = "BOOLEAN";
}


export class DateColDef<T = any> extends BaseColDef<T> {
  type: "DATE" = "DATE";
  format?: "dd/MM/yyyy" | "yyyy/MM/dd" = "dd/MM/yyyy"
}

export interface ISelectDataItem {
  value: string,
  label: string,
  color?: string
}
export class SelectColDef<T = any> extends BaseColDef<T> {
  type: "SELECT" = "SELECT";
  selectData: ISelectDataItem[] = []

}

export type ColDef<T = any> = TextColDef<T> | NumberColDef<T> | BooleanColDef<T> | DateColDef<T> | SelectColDef<T>



