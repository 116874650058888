import { Injectable } from "@angular/core";
import { PageRequest, PageResponse } from "~/@core/control/grid-control/models";
import { ApiService } from "../common";



@Injectable()
export class ConfiggeneralService {
  constructor(private apiServcie: ApiService) {

  }

  getbytype(param = {}) {
    return this.apiServcie.post("/api/inbounds/configgeneral/get-by-type", param);
  }

  list(pageRequest: PageRequest) {
    return this.apiServcie.post("/api/inbounds/configgeneral/list", {
      ...pageRequest,
    });
  }

  save(item = {}) {
    return this.apiServcie.post<PageResponse>("/api/inbounds/configgeneral/create", { ...item });
  }

  delete(ids) {
    return this.apiServcie.post("/api/inbounds/configgeneral/delete", { ids });
  }

}