import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { GridCommand, GridOption } from "./models/grid-option";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ColDef, DateColDef, FilterItemDate, FilterItemNumber, FilterItemSelect, FilterItemText, GridRow, NumberColDef, PageRequest, PageResponse, SelectColDef, TextColDef } from "./models";
import { Subject } from "rxjs";
import { NzTableComponent } from "ng-zorro-antd/table";
import { NzResizeEvent } from "ng-zorro-antd/resizable";
import { NzModalService } from "ng-zorro-antd/modal";
import { InputComponent } from "./modals/input.component";
import { ItemContextMenu } from "./components/grid-context-menu/grid-context-memu.component";
import * as XLSX from "xlsx";
import * as uuid from 'uuid';
import { saveAs } from 'file-saver';
import { ExcelInfo } from "./models/excel.model";
import { animate, style, transition, trigger } from "@angular/animations";
import { makeAutoObservable } from "mobx";

const defaultPageSizeOptions = [
  50, 100, 200, 1000, 5000, 10000, 20000
]

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "grid-control",
  styleUrls: ["./grid.control.less"],
  templateUrl: "./grid.control.html",
  animations: [
    // trigger(
    //   'enterAnimation', [
    //   transition(':enter', [
    //     style({ transform: 'translateX(100%)', opacity: 0 }),
    //     animate('500ms', style({ transform: 'translateX(0)', opacity: 1, 'overflow-x': 'hidden' }))
    //   ]),
    //   transition(':leave', [
    //     style({ transform: 'translateX(0)', opacity: 1 }),
    //     animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 }))
    //   ])
    // ]
    // )
  ]
})
export class GridControl<T = any> implements OnInit, AfterViewInit, OnDestroy, OnChanges, DoCheck {
  @Input()
  gridOption: GridOption;
  @Input()
  pageSizeOptions = defaultPageSizeOptions;
  public isInit = false;
  rightClickHeaderMenus: ItemContextMenu[] = [
    {
      title: "Change Header Name",
      onClick: (colDef: ColDef) => {
        this.modalService.confirm({
          nzTitle: `Change Header Name`,
          nzContent: InputComponent,

          nzOnOk: (cpnInstance) => {
            const { value: headerName } = cpnInstance.formControls.headerName
            colDef.headerName = headerName;
            this.changeDetector.detectChanges()
          }
        });
      }
    },
    {
      title: "Hiden colomn",
      onClick: (colDef: ColDef) => {
        colDef.hiden = true;
        this.scrollX -= colDef.width || colDef.minWidth!
        this.refreshCheckedConfigStatus();
      }
    }
  ]

  @ViewChild('virtualTable', { static: false }) nzTableComponent?: NzTableComponent<T>;
  @ViewChild('bodyGrid', { read: ElementRef, static: false }) bodyGrid: ElementRef<HTMLElement>;

  private destroy$ = new Subject();
  gridRows: GridRow<T>[] = [];
  total: number = 0;

  isLoading = false;
  isFirstLoad = true;
  pageRequest: PageRequest<T> = new PageRequest<T>();


  /**
   * CHECK BOX
   */
  indeterminate: boolean = false;
  checked: boolean = false;
  setOfCheckedId = new Set<string>();

  indeterminateConfig: boolean = false;
  checkedConfig: boolean = false;
  setOfCheckedConfigId = new Set<string>();
  /**
   * END
   */

  widthCommand = 75;

  private intervalCheckInit;
  constructor(public changeDetector: ChangeDetectorRef,
    private modalService: NzModalService,
  ) {
    this.changeDetector.detach();
    this.intervalCheckInit = setInterval(() => {
      this.changeDetector.markForCheck();
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.changeDetector.reattach();
  }

  ngOnInit() {

  }
  public scrollY: number = 0;
  public scrollX: number = 0;
  ngAfterViewInit() {
  }
  ngDoCheck() {

    if (!this.isInit && this.gridOption) {
      if (this.intervalCheckInit) {
        clearInterval(this.intervalCheckInit)
      }
      this.isInit = true;
      this.changeDetector.markForCheck();
      this.pageRequest.pageSize = this.pageSizeOptions[0];
      this.setupColDef();
      const { offsetHeight: heightBody } = this.bodyGrid.nativeElement;
      if (heightBody > this.scrollY + 70) {
        this.scrollY = heightBody - 70;
        //this.changeDetector.detectChanges();
      }
      // this.changeDetector.detectChanges();
      this.changeDetector.markForCheck();
      this.loadData();
    }
  }

  setupColDef() {
    const { columnDefs } = this.gridOption;
    this.pageRequest.filters = {};
    columnDefs.forEach((colDef) => {
      if (colDef.type === "TEXT") {
        const temp = new TextColDef();
        Object.assign(temp, colDef);
        Object.assign(colDef, temp);
        colDef.width = colDef.width || colDef.minWidth
        Object.assign(this.pageRequest.filters, {
          [colDef.field!]: new FilterItemText()
        })
      }
      if (colDef.type === "DATE") {
        const temp = new DateColDef();
        Object.assign(temp, colDef);
        Object.assign(colDef, temp);
        colDef.width = colDef.width || colDef.minWidth
        Object.assign(this.pageRequest.filters, {
          [colDef.field!]: new FilterItemDate()
        })
      }
      if (colDef.type === "NUMBER") {
        const temp = new NumberColDef();
        Object.assign(temp, colDef);
        Object.assign(colDef, temp);
        colDef.width = colDef.width || colDef.minWidth
        Object.assign(this.pageRequest.filters, {
          [colDef.field!]: new FilterItemNumber()
        })
      }

      if (colDef.type === "SELECT") {
        const temp = new SelectColDef();
        Object.assign(temp, colDef);
        Object.assign(colDef, temp);
        colDef.width = colDef.width || colDef.minWidth
        const filterItem = new FilterItemSelect();
        filterItem.value.push("")
        Object.assign(this.pageRequest.filters, {
          [colDef.field!]: filterItem
        })
      }
      // this.pageRequest.filters = filters;
    })
    this.widthCommand = (Object.values(this.gridOption.defaultAction).map(v => v).length + 1) * 25;
    this.widthCommand += this.gridOption.commands.length * 25;
    this.scrollX = columnDefs.reduce((result, colDef) => {
      result += colDef.width || colDef.minWidth!
      return result;
    }, 0);
    this.refreshCheckedConfigStatus();
    this.changeDetector.detectChanges();
  }

  get colDefShows() {
    return this.gridOption.columnDefs.filter(item => !item.hiden) || [];
  }

  get rowEditing() {
    const result = this.gridRows.filter(item => item.editable) || [];
    return result.map(gridRow => gridRow.item);
  }


  ngOnDestroy(): void {
    if (this.intervalCheckInit) {
      clearInterval(this.intervalCheckInit)
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  private cloneDataToGridRow(pageResponse: PageResponse) {
    const { data, total } = pageResponse;
    const { pageIndex, pageSize = total } = this.pageRequest
    const { primaryKeyColumns = [] } = this.gridOption
    this.total = total;
    this.gridRows = [];
    data.forEach((item, index) => {
      const gridRow = new GridRow();
      gridRow.item = item;
      gridRow.prevItem = { ...item };
      if (primaryKeyColumns.length > 0) {
        if (!gridRow.rowId) {
          gridRow.rowId = "";
        }
        for (const key of primaryKeyColumns) {
          gridRow.rowId += `${item[key] || ''}`
        }
      }
      if (!gridRow.rowId) {
        gridRow.rowId = `rowId${(pageIndex - 1) * pageSize + index}`.trim();
      }
      this.gridRows.push(gridRow);
    })
  }
  // animation;
  private async loadData() {
    this.isLoading = true;
    this.changeDetector.detectChanges();
    try {

      const res = await this.gridOption.loadData(this.pageRequest)
      this.cloneDataToGridRow(res);
      this.isLoading = false;
      this.isFirstLoad = false;
      this.refreshCheckedStatus();
      // this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    } catch (error) {
      this.isLoading = false;
      this.isFirstLoad = false;
      this.refreshCheckedStatus();
      this.changeDetector.detectChanges();
    }

  }

  async pageIndexChange(pageIndex: number) {
    this.pageRequest.pageIndex = pageIndex;
    await this.loadData();
  }

  async pageSizeChange(pageSize: number) {
    if (pageSize !== this.pageRequest.pageSize) {
      this.pageRequest.pageSize = pageSize;
      this.pageRequest.pageIndex = 1;
      await this.loadData();
    }

  }

  trackByIndex(index: number, gridRow: GridRow<T>) {
    return gridRow.rowId;
  }


  /**
   * CHECK BOX
   */

  updateChecked(gridRow: GridRow, checked: boolean) {
    if (checked) {
      this.setOfCheckedId.add(gridRow.rowId);
    } else {
      this.setOfCheckedId.delete(gridRow.rowId);
    }
  }
  onAllChecked(checked: boolean) {
    this.gridRows.forEach(it => {
      this.updateChecked(it, checked)
    })
    this.refreshCheckedStatus();
  }

  onItemChecked(gridRow: GridRow, checked: boolean) {
    this.updateChecked(gridRow, checked)
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    if (this.gridRows.length == 0) {
      this.checked = false;
    } else {
      this.checked = this.gridRows.every(gridRow => {
        return this.setOfCheckedId.has(gridRow.rowId)
      });
    }

    this.indeterminate = this.gridRows.some(gridRow => this.setOfCheckedId.has(gridRow.rowId)) && !this.checked;
  }

  getItemChecked() {
    return this.gridRows.filter(gridRow => this.setOfCheckedId.has(gridRow.rowId));
  }
  /**
   * END
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.gridOption.columnDefs, event.previousIndex, event.currentIndex);
  }
  onResize(resizeEvent: NzResizeEvent, colDef: ColDef): void {
    const { width } = resizeEvent
    if (width) {
      const sizeChange = width - (colDef.width || width);
      this.scrollX += sizeChange;
      colDef.width = resizeEvent.width;
      this.changeDetector.detectChanges();
    }
  }


  async actionFilter() {
    this.pageRequest.pageIndex = 1;
    this.setOfCheckedId.clear();
    await this.loadData();
  }

  async reload() {
    this.pageRequest.pageIndex = 1;
    this.setOfCheckedId.clear();
    await this.loadData();
  }





  visibleConfig = false;

  openConfig(): void {
    this.visibleConfig = true;
  }

  closeConfig(): void {
    this.visibleConfig = false;
  }



  onAllCheckedConfig(checkedConfig: boolean) {
    this.gridOption.columnDefs.forEach(it => {
      it.hiden = !checkedConfig
    });
    this.scrollX = this.colDefShows.reduce((result, colDef) => {
      result += colDef.width || colDef.minWidth!;
      return result;
    }, 0)
    this.refreshCheckedConfigStatus();
  }
  onItemCheckedConfig(colDef: ColDef, checkedConfig: boolean) {
    colDef.hiden = !checkedConfig;
    if (checkedConfig) {
      this.scrollX += colDef.width || colDef.minWidth!;
    } else {
      this.scrollX -= colDef.width || colDef.minWidth!;
    }
    this.refreshCheckedConfigStatus();
  }

  refreshCheckedConfigStatus(): void {
    if (this.gridOption.columnDefs.length == 0) {
      this.checkedConfig = false;
    } else {
      this.checkedConfig = this.gridOption.columnDefs.every(colDef => {
        return !colDef.hiden
      });
    }

    this.indeterminateConfig = this.gridOption.columnDefs.some(colDef => !colDef.hiden) && !this.checkedConfig;
  }



  async exportExcel() {
    const res = await this.gridOption.loadData({
      ...this.pageRequest,
      pageIndex: 1,
      pageSize: this.total
    })
    const excelInfo = new ExcelInfo();

    res.data.forEach((item) => {
      const itemExcel = {};
      this.colDefShows.forEach((colDef: ColDef) => {
        Object.assign(itemExcel, {
          [colDef.headerName!]: item[colDef.field]
        })
      })
      excelInfo.data.push(itemExcel);

    })

    /* Worksheet */
    const worksheet = XLSX.utils.json_to_sheet(excelInfo.data, { cellDates: true });
    /* Workbook */
    const workbook = XLSX.utils.book_new();
    /* Add worksheet to workbook */
    XLSX.utils.book_append_sheet(workbook, worksheet, excelInfo.sheetName || 'Default');

    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const fileName = excelInfo.fileName ? excelInfo.fileName + this.current() : uuid.v4() + '.xlsx';
    saveAs(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }), fileName);
  }
  private current(): string {
    const now = new Date();
    const fullYear = now.getFullYear();
    const date = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const hour = now.getHours().toString().padStart(2, '0');
    const minute = now.getMinutes().toString().padStart(2, '0');
    const second = now.getSeconds().toString().padStart(2, '0');
    return `${fullYear}/${month}/${date} ${hour}:${minute}:${second}`;
  }
  private s2ab(s): ArrayBuffer {
    const buffer = new ArrayBuffer(s.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i !== s.length; ++i) {
      // tslint:disable-next-line:no-bitwise
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buffer;
  }

  editRow(gridRow: GridRow) {
    gridRow.editable = true;
    gridRow.prevItem = { ...gridRow.item }
    this.changeDetector.detectChanges();
  }
  async saveRow(gridRow: GridRow, indexRow: number) {
    gridRow.isLoading = true;
    if (this.gridOption.onSave) {
      try {
        const res = await this.gridOption.onSave(gridRow.item, gridRow);
        if (res) {
          gridRow.editable = false;
          if (!gridRow.rowId) {
            const { primaryKeyColumns } = this.gridOption
            const { item } = gridRow;
            if (primaryKeyColumns.length > 0) {
              gridRow.rowId = "";
              for (const key of primaryKeyColumns) {
                gridRow.rowId += `${item[key] || ''}`
              }
            }
            if (!gridRow.rowId) {
              gridRow.rowId = `rowId${uuid.v4()}`.trim();
            }
          }

        }
      } catch (error) {
      }
    }
    gridRow.isLoading = false;
    this.changeDetector.detectChanges();
  }


  async cancelSave(gridRow: GridRow, indexRow: number) {
    if (!gridRow.rowId) {
      const cloneArray = [...this.gridRows];
      cloneArray.splice(indexRow, 1);
      this.gridRows = cloneArray;
    } else {
      gridRow.item = { ...gridRow.prevItem! }
      gridRow.editable = false;
    }

    this.changeDetector.detectChanges();
  }



  createEmptyItem() {
    const emptyItem = {};
    const { columnDefs } = this.gridOption;
    columnDefs.forEach(colDef => {
      Object.assign(emptyItem, {
        [colDef.field]: undefined
      })
    })
    return emptyItem;
  }
  scrollToIndex(index: number): void {
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrollToIndex(index);
  }

  addRow() {
    const gridRow = new GridRow();
    gridRow.editable = true;
    gridRow.item = this.createEmptyItem();
    const cloneArray = [...this.gridRows];
    cloneArray.unshift(gridRow);
    this.gridRows = cloneArray;
    this.scrollToIndex(0);
    this.changeDetector.detectChanges();
  }

  async onDelete(gridRow: GridRow, indexRow: number) {
    gridRow.isLoading = true;
    if (this.gridOption.onDelete) {
      try {
        const res = await this.gridOption.onDelete(gridRow.item, gridRow);
        if (res) {
          const cloneArray = [...this.gridRows];
          cloneArray.splice(indexRow, 1);
          this.gridRows = cloneArray;
        }
      } catch (error) {
      }
    }
    gridRow.isLoading = false;
    this.changeDetector.detectChanges();
  }


  isDisableCommand = (command: GridCommand, gridRow: GridRow) => {
    if (!command.disabled) {
      return false;
    }
    if (typeof command.disabled === 'boolean') {
      return command.disabled;
    }
    return command.disabled(gridRow.item, gridRow);
  }

  isHidenCommand = (command: GridCommand, gridRow: GridRow) => {
    if (!command.hidden) {
      return false;
    }
    if (typeof command.hidden === 'boolean') {
      return command.hidden;
    }
    return command.hidden(gridRow.item, gridRow);
  }

  // @ViewChild('modalImportExcel') modalImportExcel: ModalControl;
  // importedItems = [];
  // importExcel() {
  //   this.excelService.import().then((lines: any[]) => {
  //     this.importedItems = lines.map(line => {
  //       const record = {};
  //       this.gridOption.columns.forEach(column => {
  //         record[column.field] = line[column.title];
  //         if (column.type === 'number') {
  //           record[column.field] = record[column.field] || 0;
  //         } else if (column.type === 'bool') {
  //           record[column.field] = record[column.field] || 'false';
  //           // tslint:disable-next-line: max-line-length
  //           if (record[column.field] == 1 || record[column.field].toString().toLowerCase() === 'default' || record[column.field].toString().toLowerCase() === 'true') {
  //             record[column.field] = true;
  //           } else {
  //             record[column.field] = false;
  //           }
  //         }
  //         else if (column.type === 'string') record[column.field] = record[column.field] || '';
  //       });
  //       return record;
  //     });
  //     this.modalImportExcel.open();
  //   });
  // }


  // saveImportedExcel() {
  //   if (this.gridOption.onImportExcel) {
  //     this.gridOption.onImportExcel.bind(this.gridOption.component)(this.importedItems);
  //   }
  //   this.onImportExcel.emit(this.importedItems);
  // }
}



