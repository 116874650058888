import { StringUtils } from "./string.utils";

function deserializeObject<T>(json: any, clazz: { new(): T }): T {
  try {
    return new clazz()
  } catch (error) {
    return new clazz()
  }
}
function pipeSnakeCaseToCamelCase(input: any) {
  if (input instanceof Array) {
    const arr: any[] = [];
    for (const item of input) {
      const output = pipeSnakeCaseToCamelCase(item);
      arr.push(output);
    }
    return arr;
  }
  if (input instanceof Object) {
    const output = {};
    const keys = Object.keys(input);
    for (const key of keys) {
      const value = input[key];
      const newKey = StringUtils.snakeCaseToCamelCase(key);
      if (value instanceof Object || value instanceof Array) {
        const newValue = pipeSnakeCaseToCamelCase(value);
        Object.assign(output, {
          [newKey]: newValue
        })
      } else {
        Object.assign(output, {
          [newKey]: value
        })
      }
    }
    return output;
  }

  return input;

}

export const JsonUtils = {
  deserializeObject,
  pipeSnakeCaseToCamelCase,
}
