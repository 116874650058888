import { Injectable } from "@angular/core";
import { PageRequest, PageResponse } from "~/@core/control/grid-control/models";
import { AuthService } from "../auth/auth.service";
import { ApiService } from "../common";



@Injectable()
export class PartnerService {
  constructor(private apiServcie: ApiService,
    private authService: AuthService) {

  }

 
  all() {
    return this.apiServcie.post("/api/inbounds/partner/all");
  }

  list(pageRequest: PageRequest) {
    return this.apiServcie.post("/api/inbounds/partner/list", pageRequest);
  }

  save(item = {}) {
    return this.apiServcie.post<PageResponse>("/api/inbounds/partner/create", { ...item });
  }

  delete(ids) {
    return this.apiServcie.post("/api/inbounds/partner/delete", { ids });
  }

}