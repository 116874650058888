const formatDate = (value) => {
    if (!value) return null;
    let date = new Date(value), month = '' + (date.getUTCMonth() + 1), day = '' + date.getUTCDate(), year = date.getUTCFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
}

const formatToDate = (value) => {
    if (!value) return null;
    let date = new Date(value), month = '' + (date.getUTCMonth() + 1), day = '' + (date.getUTCDate() + 1), year = date.getUTCFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
}


export const DateUtils = {
    formatDate,
    formatToDate
}