import { NgModule } from '@angular/core';
import { LayoutModule } from './layout/layout.module';
import { IconsProviderModule } from './icons-provider/icons-provider.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ControlModule } from './control/control.module';
const globalModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  IconsProviderModule,
  LayoutModule,
  RouterModule,
  HttpClientModule,
  ControlModule
]
@NgModule({
  declarations: [

  ],
  imports: [
    ...globalModules,

  ],
  exports: [...globalModules]
})
export class CoreModule {

}
