import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { PageRequest, PageResponse } from "~/@core/control/grid-control/models";
import { AuthService } from "../auth/auth.service";
import { ApiService } from "../common";



@Injectable({ providedIn: 'root' })
export class UserService implements CanActivate {
  constructor(private apiServcie: ApiService,
    private router: Router,
    protected authService: AuthService,
    ) {

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean> {
    try {
      const userInfo = JSON.parse(localStorage.getItem('currentUser'))
      if(userInfo){
        this.authService.currentUser = userInfo;
      }else{
        throw 'LOCALSTORAGE EMPTY'
      }
      return true;
    } catch (err) {
      this.router.navigate(['/login']);
      return false;
    }
  }



  login(item = {}) {
    return this.apiServcie.post("/api/inbounds/user/login", { ...item });
  }

  all() {
    return this.apiServcie.post("/api/inbounds/user/all");
  }

  getByUsername(item = {}) {
    return this.apiServcie.post("/api/inbounds/user/get-by-username", { ...item });
  }

  list(pageRequest: PageRequest) {
    return this.apiServcie.post("/api/inbounds/user/list", {
      ...pageRequest,
    });
  }

  save(item = {}) {
    return this.apiServcie.post<PageResponse>("/api/inbounds/user/create", { ...item });
  }

  delete(ids) {
    return this.apiServcie.post("/api/inbounds/user/delete", { ids });
  }

}