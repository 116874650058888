import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthService } from "~/app/services/auth";
import { KeyHeader } from "~/app/services/common/key-header";
import { MessageService } from "~/app/services/common/message.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'main-layout',
  templateUrl: './main.layout.html',
  styleUrls: ['./main.layout.less']
})
export class MainLayout {
  isCollapsed = false;

  listWareHouse: {
    whseid: string,
    name: string
  }[] = [];
  selectedWhseid = "";
  userInfo;;
  constructor(protected authService: AuthService, private messageService: MessageService) {
    this.userInfo = this.authService.currentUser
  }

  logout(event) {
    this.authService.logout();
  }



  onChangeWareHouse(whseid: string): void {
    this.selectedWhseid = whseid;
    this.authService.selectWarehouse(whseid)
  }
}
