<ng-container>
  <div class="grid-context-menu" (contextmenu)="contextMenu($event, menu)">
    <ng-content></ng-content>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <ng-container *ngFor="let menuItem of contextMenus;let index;">
        <li nz-menu-item (click)="menuItem.onClick(data)">{{menuItem.title}}</li>
      </ng-container>
    </ul>
  </nz-dropdown-menu>
</ng-container>
