import { NzTableSize } from "ng-zorro-antd/table";
import { BaseColDef, ColDef } from "./grid-column";
import { GridRow } from "./grid-row";
import { PageRequest, PageResponse } from "./page.model";

export type ThemeType = 'fill' | 'outline' | 'twotone';
export class GridCommand<T = any> {
  icon: {
    nzType: string;
    nzTheme: ThemeType;
    color?: string;
    size?: string;
  };
  tooltip?: string = "";
  onClick?: (item: T, gridRow: GridRow) => void | Promise<void>;
  disabled?: boolean | ((item: T, gridRow: GridRow) => boolean);
  hidden?: boolean | ((item: T, gridRow: GridRow) => boolean);
}

export class GridOption<T = any>{
  type: "LOCAL" | "SERVER" = "SERVER";
  defaultColDef: Omit<BaseColDef<T>, "field"> = {
    sortable: true,
    resizable: true,
    editable: true,
  };
  addable: boolean = false;
  primaryKeyColumns: (keyof T)[];
  columnDefs: ColDef<T>[] = [];
  animateRows: boolean = true;
  rowSelection: "single" | "multiple" = "multiple";
  virtualItemSize: number = 20;
  gridSize: NzTableSize = "small";
  /**
   * CUSTOM
   */
  defaultAction: {
    edit?: boolean,
    delete?: boolean
  } = {
      edit: true,
      delete: true
    }
  loadData: (pageRequest: PageRequest) => Promise<PageResponse<T>>;
  showCheckBox: boolean = true;
  rowDrag?: boolean = true;
  onSave?: (item: T, gridRow: GridRow) => Promise<boolean> | boolean;
  onDelete?: (item: T, gridRow: GridRow) => Promise<boolean> | boolean;
  onImportExcel?(items: any[]): void;

  commands: GridCommand[] = [];
  /**
   * END
   */

}







