import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators, NgControl } from '@angular/forms';
@Component({
  styleUrls: ['./input.component.css'],
  templateUrl: './input.component.html',
})
export class InputComponent implements OnInit {

  validateForm: FormGroup;
  constructor(private modal: NzModalRef, private formBuilder: FormBuilder) { }
  get formControls() { return this.validateForm.controls; }
  ngOnInit() {
    this.validateForm = this.formBuilder.group({
      headerName: ["", [Validators.required], {}],
    });
  }


  async onSubmit(event) {

    if (this.validateForm.invalid) {
      await this.modal.triggerOk();
    }

  }
}
