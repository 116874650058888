import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { ApiService } from './services/common/api.service';

import { NZ_I18N, vi_VN, en_US } from 'ng-zorro-antd/i18n';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private nzI18nService: NzI18nService
  ) {
    this.nzI18nService.setLocale(vi_VN)
  }

  async ngOnInit(): Promise<void> {
  }
}
