import { Injectable } from "@angular/core";
import { PageRequest, PageResponse } from "~/@core/control/grid-control/models";
import { ApiService } from "../common";



@Injectable()
export class DepartmentService {
  constructor(private apiServcie: ApiService) {

  }

  all() {
    return this.apiServcie.post("/api/inbounds/department/all");
  }

  list(pageRequest: PageRequest) {
    return this.apiServcie.post("/api/inbounds/department/list", {
      ...pageRequest,
    });
  }

  save(item = {}) {
    return this.apiServcie.post<PageResponse>("/api/inbounds/department/create", { ...item });
  }

  delete(ids) {
    return this.apiServcie.post("/api/inbounds/department/delete", {ids});
  }
 
}