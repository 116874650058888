import { NgModule } from '@angular/core';
import { LoadingView } from './loading-view/loading.view';
import { GridControl } from './grid-control/grid.control';
import { InputComponent } from './grid-control/modals/input.component';
import { GridContextMemuComponent } from './grid-control/components/grid-context-menu/grid-context-memu.component';
import { GridColDateComponent } from './grid-control/components/grid-col-date/grid-col-date.component';
import { GridColTextComponent } from './grid-control/components/grid-col-text/grid-col-text.component';
import { GridColNumberComponent } from './grid-control/components/grid-col-number/grid-col-number.component';
import { GridColSelectComponent } from './grid-control/components/grid-col-select/grid-col-select.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IconsProviderModule } from '../icons-provider/icons-provider.module';
import { LayoutModule } from '../layout/layout.module';
import { MobxAngularModule } from 'mobx-angular';



@NgModule({
  declarations: [GridControl, LoadingView,
    GridContextMemuComponent,
    InputComponent,
    GridColDateComponent,
    GridColTextComponent,
    GridColNumberComponent,
    GridColSelectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconsProviderModule,
    LayoutModule,
    RouterModule,
    HttpClientModule,
    MobxAngularModule
  ],
  exports: [GridControl, LoadingView]
})
export class ControlModule {

}
