<ng-container *ngIf="colDef && gridRow">
  <div nz-tooltip nzTooltipTitle="{{selected.label}}">
    <ng-container *ngIf="gridRow.rowId; else addTemplate">
      <ng-container *ngIf="!isDisable; else disableTemplate">
        <ng-container *ngIf="!(colDef.editable || gridRow.editable)">
          <span [style.color]="selected.color">{{selected.label}}</span>
        </ng-container>
        <nz-select *ngIf="colDef.editable || gridRow.editable" [(ngModel)]="gridRow.item[colDef.field!]"
          (ngModelChange)="onChangeColumn()" (keyup.Enter)="onEnterColumn()" style="width: 100%;">
          <nz-option *ngFor="let item of colDef.selectData" nzValue="{{item.value}}" nzLabel="{{item.label}}">
          </nz-option>
        </nz-select>
      </ng-container>
      <ng-template #disableTemplate>
        <ng-container>
          <span [style.color]="selected.color">{{selected.label}}</span>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #addTemplate>
      <nz-select [(ngModel)]="gridRow.item[colDef.field!]" (ngModelChange)="onChangeColumn()"
        (keyup.Enter)="onEnterColumn()" style="width: 100%;">
        <nz-option *ngFor="let item of colDef.selectData" nzValue="{{item.value}}" nzLabel="{{item.label}}"></nz-option>
      </nz-select>
    </ng-template>

  </div>
</ng-container>
