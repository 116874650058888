<ng-container *ngIf="colDef && gridRow">
  <div *ngIf="colDef" nz-tooltip nzTooltipTitle="{{gridRow.item[colDef.field!] | date: colDef.format || 'dd/MM/yyyy'}}">
    <ng-container *ngIf="gridRow.rowId;else addTemplate">
      <ng-container *ngIf="!isDisable;else disableTemplate">

        <ng-container *ngIf="!(colDef.editable || gridRow.editable)">
          <span>{{gridRow.item[colDef.field!] | date: colDef.format || 'dd/MM/yyyy'}}</span>
        </ng-container>
        <nz-date-picker *ngIf="colDef.editable ||  gridRow.editable" style="width: 100%;"
          [nzFormat]="colDef.format || 'dd/MM/yyyy'" [(ngModel)]="gridRow.item[colDef.field!]"
          (ngModelChange)="onChangeColumn()" (keyup.Enter)="onEnterColumn()">
        </nz-date-picker>

      </ng-container>
      <ng-template #disableTemplate>
        <ng-container>
          <span>{{gridRow.item[colDef.field!] | date: colDef.format || 'dd/MM/yyyy'}}</span>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #addTemplate>
      <nz-date-picker style="width: 100%;" [nzFormat]="colDef.format || 'dd/MM/yyyy'"
        [(ngModel)]="gridRow.item[colDef.field!]" (ngModelChange)="onChangeColumn()" (keyup.Enter)="onEnterColumn()">
      </nz-date-picker>
    </ng-template>
  </div>
</ng-container>