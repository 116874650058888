import { IObjectPromise } from "../dto";

const promiseWhen = (promises: Promise<any>[]) => {
  return new Promise((resolve, reject) => {
    let errors: any[] = [];
    let result: any[] = [];
    let count = 0;
    if (promises.length === 0) {
      resolve({
        errors,
        result
      });
    } else {
      promises.map((promise, idx) => {
        promise.then(res => {
          result.push(res);
          count += 1;
          if (count === promises.length) {
            resolve({
              errors,
              result
            });
          }
        }).catch(err => {
          errors.push(err.message);
          count += 1;
          if (count === promises.length) {
            resolve({
              errors,
              result
            });
          }
        });
      });
    }

  });
};
const promiseAllObject = async (promiseObj: IObjectPromise = {}) => {
  const list: any[] = [];
  for (let index = 0; index < Object.keys(promiseObj).length; index++) {
    const key = Object.keys(promiseObj)[index];
    list.push(promiseObj[key]())
  }
  const res = await Promise.all(list)
  const output = {};
  for (let index = 0; index < Object.keys(promiseObj).length; index++) {
    const key = Object.keys(promiseObj)[index];
    Object.assign(output, {
      [key]: res[index]
    })
  }
  return output;
}

async function retryPromise<T = any>(promise: Promise<T>, limit: number, idxRetry = 0) {
  const res = await promise.catch(err => {
    if (idxRetry > limit) {
      throw err;
    }
    return undefined;
  })
  if (!res) {
    return retryPromise(promise, limit, idxRetry + 1)
  }
  return res;
}

export const PromiseUtils = {
  promiseWhen,
  promiseAllObject,
  retryPromise
}
