
// type TPrimitive = number | Number | string | String | boolean | Boolean | Date


type TOperatorText = '=' | 'LIKE' | 'NOT LIKE';
type TOperatorNumber = '=' | '!=' | '<' | '>' | '>=' | '<=';
type TOperatorDate = '=' | '!=' | '<' | '>' | '>=' | '<=';
type TOperatorSelect = 'IN' | 'NOT IN'
type TOperatorBoolean = '=';

export class FilterItemText {
  type: "TEXT" = "TEXT";
  value: string = "";
  compare?: TOperatorText = "LIKE";
}
export class FilterItemNumber {
  type: "NUMBER" = "NUMBER";
  value: number
  compare?: TOperatorNumber = "=";
}
export class FilterItemDate {
  type: "DATE" = "DATE";
  value: Date;
  compare?: TOperatorDate = "=";
}
export class FilterItemBoolean {
  type: "BOOLEAN" = "BOOLEAN";
  value: boolean;
  compare?: TOperatorBoolean = "=";
}

export class FilterItemSelect {
  type: "SELECT" = "SELECT";
  value: any[] = [];
  compare?: TOperatorSelect = "IN";
}

export type FilterOption<T = any> = {
  [k in keyof T]?: FilterItemText | FilterItemNumber | FilterItemDate | FilterItemBoolean | FilterItemSelect
}


export type OrderOption<T = any> = {
  [k in keyof T]?: "ASC" | "DESC" | ""
}

export class PageRequest<T = any> {
  pageSize?: number;
  pageIndex = 1;
  filters?: FilterOption<T>
  orders?: OrderOption<T>
  param?:any
}

export class PageResponse<T = any> {
  data: T[] = [];
  total: number = 0;
}
