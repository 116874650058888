import { Injectable, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { NzMessageDataOptions, NzMessageRef, NzMessageService } from "ng-zorro-antd/message";
import { ApiException } from "~/@core/dto";



@Injectable({ providedIn: 'root' })
export class MessageService {
  constructor(private readonly nzMessageService: NzMessageService, private router: Router) {

  }

  success(content: string | TemplateRef<void> = "DONE", options?: NzMessageDataOptions) {
    this.nzMessageService.success(content, options);
  }
  error(content: string | TemplateRef<void>, options?: NzMessageDataOptions) {
    this.nzMessageService.error(content, options);
  }
  info(content: string | TemplateRef<void>, options?: NzMessageDataOptions) {
    this.nzMessageService.info(content, options);
  }
  warning(content: string | TemplateRef<void>, options?: NzMessageDataOptions) {
    this.nzMessageService.warning(content, options);
  }
  loading(content: string | TemplateRef<void>, options?: NzMessageDataOptions) {
    this.nzMessageService.loading(content, options);
  }
  create(type: 'success' | 'info' | 'warning' | 'error' | 'loading' | string, content: string | TemplateRef<void>, options?: NzMessageDataOptions) {
    this.nzMessageService.create(type, content, options);
  }
  handleError(error: ApiException) {
    if (error.httpCode === 401) {
      this.error(error.message || "Unknown")
      this.router.navigate(["/login"]);
      return;
    }
    if (error.errors) {
      if(error.errors.message) {
      this.error(error.errors['message'] || "Unknown")

      }else
      // parse authen old error 
      this.error(error.errors["ExceptionMessage"] || "Unknown")
    } else {
      this.error(error.message || "Unknown")
    }

  }



}