import { Component, Input } from "@angular/core";
import { NzContextMenuService, NzDropdownMenuComponent } from "ng-zorro-antd/dropdown";



export class ItemContextMenu {
  title: string = '';
  onClick: Function
}

@Component({
  selector: "grid-context-memu",
  templateUrl: "./grid-context-memu.component.html",
  styleUrls: ["./grid-context-memu.component.less"]
})

export class GridContextMemuComponent<T = any>  {


  @Input()
  contextMenus: ItemContextMenu[] = [];
  @Input() data: T;

  constructor(private nzContextMenuService: NzContextMenuService) { }
  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent): void {
    this.nzContextMenuService.create($event, menu);
  }
  closeMenu(): void {
    this.nzContextMenuService.close();
  }
}
