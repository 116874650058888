



import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import vi from '@angular/common/locales/vi';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '~/@core';
import { MainLayout } from './layouts/main/main.layout';
import { ControlModule } from '../@core/control/control.module';
import { NZ_I18N, vi_VN, en_US } from 'ng-zorro-antd/i18n';
import { AgGridModule } from '@ag-grid-community/angular';
import { LoginPage } from './pages/login/login.page';




registerLocaleData(en);
registerLocaleData(vi);

@NgModule({
  declarations: [
    AppComponent,
    MainLayout,
    LoginPage,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    ControlModule,
    AppRoutingModule,
    AgGridModule.withComponents([]),
  ],
  providers: [
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => {
        switch (localId) {
          case 'en':
            return en_US;
          /** keep the same with angular.json/i18n/locales configuration **/
          case 'vi':
            return vi_VN;
          default:
            return vi_VN;
        }
      },
      deps: [LOCALE_ID]
    },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
