import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthApiService } from "../common";
import { KeyHeader } from "../common/key-header";
import { AuthModel, StorerModel, WarehouseModel } from "./auth.model";



@Injectable({ providedIn: 'root' })
export class AuthService implements CanActivate {
  modelName = 'User';
  loginUrl = '/login';
  homeUrl = '/main';
  currentUser: AuthModel;
  currentWarehouse: WarehouseModel;
  avatarUrl: string = '/assets/img/users/admin.png';
  constructor(protected authApiService: AuthApiService,
    private router: Router,) { }


  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      if (!this.currentUser) { await this.authenticate(); }
      return true;
    } catch (err) {
      this.router.navigate([this.loginUrl]);
      return false;
    }
  }
  async authenticate() {
    const res = await this.authApiService.post<AuthModel>(`/api/${this.modelName}/authenticate`, null);
    this.currentUser = res;
    return this.init();
  }
  async login(username: string, password: string) {
    const res = await this.authApiService.post<AuthModel>(`/api/${this.modelName}/login`, {
      username: username,
      password: password
    })
    this.currentUser = res;
    localStorage.setItem(KeyHeader.token, this.currentUser.token);
    return this.init();
  }
  async logout() {
    this.currentUser = null;
    localStorage.clear();
    this.router.navigate([this.loginUrl]);
  }
  private init() {
    // Kiểm tra user có được assign warehouse nào không?
    if (Object.keys(this.currentUser.warehouse).length === 0 && !this.currentUser.isMasterClient) {
      throw new Error('NO WAREHOUSE ASSIGNED');
    }

    // Lấy kho
    this.currentUser.warehouses = Object.values(this.currentUser.warehouse);
    this.currentUser.strWarehouses = this.currentUser.warehouses.map(e => `'${e.code}'`).join();

    // Lấy Owner
    this.currentUser.warehouses.map(e => {
      e.storers = Object.values(e.storer);
    });

    // Thiết lập warehouse mặc định
    this.selectWarehouse();

    return this.currentUser;
  }
  selectWarehouse(warehouseCode?: string): void {
    if (!warehouseCode) {
      // Read warehouse from LocalStorage
      warehouseCode = localStorage.getItem(KeyHeader.whseid);
      // Kiểm tra mã kho có tồn tại trong danh sách mã kho gửi xuống.
      if (this.currentUser.warehouses.findIndex(e => e.code === warehouseCode) < 0) {
        warehouseCode = Object.keys(this.currentUser.warehouse)[0];
      }
    }
    this.currentWarehouse = this.currentUser.warehouse[warehouseCode];
    this.currentWarehouse.storers = Object.values(this.currentWarehouse.storer);
    this.currentWarehouse.strOwners = this.currentWarehouse.storerCodes.map(storerCode => `'${storerCode}'`).join();
    // Save localstorage
    localStorage.setItem(KeyHeader.whseid, warehouseCode);
    // window.location.href = '/main';
  }

  get wareHouse() {
    return this.currentUser.warehouse;
  }
  get isMasterUser() {
    return this.currentUser.isMasterUser && this.currentUser.isMasterClient;
  }

  get isSuperUser() {
    return this.currentUser.isMasterUser;
  }

  get listWareHouse() {
    return Object.values(this.currentUser.warehouse).map(wh => {
      return {
        whseid: wh.code,
        name: wh.name
      }
    }) || [];
  }


  listAllStorer(): StorerModel[] {
    const storerObj = {};
    Object.values(this.wareHouse).forEach(wh => {
      Object.assign(storerObj, {
        ...wh.storer
      })
    })
    return Object.values(storerObj);
  }

  getListStorerByWareHouse(whseid: string) {
    if (!whseid) {
      return [];
    }
    if (!this.wareHouse[whseid] || !this.wareHouse[whseid].storers) {
      return [];
    }
    const res = this.wareHouse[whseid].storers || [];
    console.log('-------------------');
    console.log({ res });
    console.log('-------------------');
    return res;
  }
}