import { Component, Input, OnInit } from "@angular/core";
import { DateColDef, GridRow, SelectColDef, TextColDef } from "../../models";



@Component({
  selector: "grid-col-select",
  templateUrl: "grid-col-select.component.html",
  styleUrls: ["grid-col-select.component.less"]
})
export class GridColSelectComponent {


  @Input()
  gridRow: GridRow;

  @Input()
  colDef: SelectColDef;

  get selected() {
    return this.colDef.selectData.find(item => item.value === this.gridRow.item[this.colDef.field]) || {
      value: "",
      label: "",
    }
  }


  onChangeColumn() {
    if (this.colDef && this.colDef.onChange) {
      this.colDef.onChange(this.gridRow.item, this.gridRow)
    }
  }
  onEnterColumn() {
    if (this.colDef && this.colDef.onEnter) {
      this.colDef.onEnter(this.gridRow.item, this.gridRow)
    }
    this.gridRow.editable = false;
  }
  get isDisable() {
    if (!this.colDef.disabled) {
      return false;
    }
    if (typeof this.colDef.disabled === 'boolean') {
      return this.colDef.disabled;
    }
    return this.colDef.disabled(this.gridRow.item, this.gridRow);
  }
}
