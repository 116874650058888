import { Injectable } from "@angular/core";
import { PageRequest, PageResponse } from "~/@core/control/grid-control/models";
import { ApiService } from "../common";



@Injectable()
export class CodelistService {
  constructor(private apiServcie: ApiService) {

  }
  list(pageRequest: PageRequest) {
    return this.apiServcie.post("/api/inbounds/codelist/list", pageRequest);
  }

  save(item = {}) {
    return this.apiServcie.post("/api/inbounds/codelist/create", { ...item });
  }

  delete(ids) {
    return this.apiServcie.post("/api/inbounds/codelist/delete", { ids });
  }

}