<form [nzLayout]="'inline'" nz-form [formGroup]="validateForm" (ngSubmit)="onSubmit($event)">
  <div nz-row>
    <div nz-col [nzSpan]="24" [nzPull]="0">
      <nz-form-item nzFlex>
        <nz-form-control [nzSpan]="24" nzErrorTip="Please input header name">
          <nz-input-group>
            <input type="text" nz-input formControlName="headerName" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
