import { Injectable } from "@angular/core";
import { PageRequest, PageResponse } from "~/@core/control/grid-control/models";
import { AuthService } from "../auth/auth.service";
import { ApiService } from "../common";



@Injectable()
export class CompanyService {
  constructor(private apiServcie: ApiService,
    private authService: AuthService) {

  }

  getbyuser(item = {}) {
    return this.apiServcie.post("/api/inbounds/company/getbyuser", { ...item });
  }


  all() {
    return this.apiServcie.post("/api/inbounds/company/all");
  }

  list(pageRequest: PageRequest) {
    return this.apiServcie.post("/api/inbounds/company/list", {
      ...pageRequest,
    });
  }

  save(item = {}) {
    return this.apiServcie.post<PageResponse>("/api/inbounds/company/create", { ...item });
  }

  delete(ids) {
    return this.apiServcie.post("/api/inbounds/company/delete", {ids});
  }
 
}