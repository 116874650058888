<ng-container *ngIf="colDef && gridRow">
  <div nz-tooltip nzTooltipTitle="{{gridRow.item[colDef.field!] }}">
    <ng-container *ngIf="gridRow.rowId; else addTemplate">
      <ng-container *ngIf="!isDisable; else disableTemplate">
        <ng-container *ngIf="!(colDef.editable || gridRow.editable)">
          {{gridRow.item[colDef.field!]}}
        </ng-container>
        <input *ngIf="colDef.editable || gridRow.editable" type="text" nz-input
          placeholder="Search {{colDef.headerName}}" [(ngModel)]="gridRow.item[colDef.field!]"
          (ngModelChange)="onChangeColumn()" (keyup.Enter)="onEnterColumn()" />

      </ng-container>
      <ng-template #disableTemplate>
        <ng-container>
          {{gridRow.item[colDef.field!]}}
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #addTemplate>
      <input type="text" nz-input placeholder="Search {{colDef.headerName}}" [(ngModel)]="gridRow.item[colDef.field!]"
        (ngModelChange)="onChangeColumn()" (keyup.Enter)="onEnterColumn()" />
    </ng-template>
  </div>
</ng-container>