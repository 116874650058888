<div #containerGrid style="height: 100%;display: flex; flex-direction: column;">

  <div #bodyGrid style="flex: 1; ">
    <ng-container *ngIf="!isInit || isFirstLoad;else tableTemplate ">
      <nz-skeleton style="width: 100%;" [nzLoading]="isFirstLoad" [nzActive]="true" [nzParagraph]="{ rows: 20 }">
      </nz-skeleton>
    </ng-container>
    <ng-template #tableTemplate>
      <nz-table *ngIf="gridOption" style="width: 100%;height: 100%;" #virtualTable [nzBordered]="true"
        [nzSize]="gridOption.gridSize" [nzVirtualItemSize]="gridOption.virtualItemSize"
        [nzVirtualForTrackBy]="trackByIndex" [nzScroll]="{
    x: scrollX+ 'px',
    y: 'calc(100vh - 300px)'
}" [nzFrontPagination]="false" [nzShowPagination]="false" [nzLoading]="isLoading" [nzData]="gridRows">
        <thead>
          <tr cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
            <th *ngIf="gridOption.showCheckBox" nzWidth="30px" nzLeft class="grid-fixed-column" [nzChecked]="checked"
              [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>
            <th nzLeft class="grid-fixed-column" [nzWidth]="widthCommand + 'px'">
              <div class="flex-center-container">
                <button *ngIf="gridOption.addable" nz-button nzType="dashed" nzGhost [nzSize]="'large'"
                  (click)="addRow()"><i nz-icon nzType="plus" nzTheme="outline"
                    class="icon-size16 primary-color"></i></button>
              </div>
            </th>
            <ng-container *ngFor="let colDef of colDefShows">
              <th nzColumnKey={{colDef.colId}} nz-resizable nzBounds="window" nzPreview [nzWidth]="colDef.width + 'px'"
                (nzResizeEnd)="onResize($event, colDef)">
                <grid-context-memu [data]="colDef" [contextMenus]="rightClickHeaderMenus">
                  <div nz-tooltip nzTooltipTitle="{{colDef.headerName}}" class="column-box" cdkDrag nzEllipsis>
                    {{colDef.headerName}}
                  </div>
                  <nz-resize-handle nzDirection="right">
                    <div class="resize-trigger"></div>
                  </nz-resize-handle>

                  <nz-date-picker *ngIf="colDef.type == 'DATE'" [nzFormat]="colDef.format || 'dd/MM/yyyy'"
                    [(ngModel)]="pageRequest.filters![colDef.field].value" style="width: 100%;"
                    (ngModelChange)="actionFilter()" (keyup.Enter)="actionFilter()"></nz-date-picker>
                  <input *ngIf="colDef.type == 'TEXT'" type="text" nz-input placeholder="Search {{colDef.headerName}}"
                    [(ngModel)]="pageRequest.filters![colDef.field].value" (keyup.Enter)="actionFilter()" />
                  <nz-input-number *ngIf="colDef.type == 'NUMBER'"
                    [(ngModel)]="pageRequest.filters![colDef.field].value" style="width: 100%;"
                    (keyup.Enter)="actionFilter()"></nz-input-number>

                  <nz-select *ngIf="colDef.type == 'SELECT'" (ngModelChange)="actionFilter()"
                    (keyup.Enter)="actionFilter()" [(ngModel)]="pageRequest.filters![colDef.field].value[0]"
                    style="width: 90%;">
                    <nz-option nzValue="" nzLabel="ALL"></nz-option>
                    <nz-option *ngFor="let item of colDef.selectData" nzValue="{{item.value}}" nzLabel="{{item.label}}">
                    </nz-option>

                  </nz-select>
                </grid-context-memu>

              </th>
            </ng-container>

          </tr>
        </thead>
        <tbody>
          <ng-template nz-virtual-scroll let-gridRow let-index="index">
            <tr>
              <td nzLeft [nzChecked]="setOfCheckedId.has(gridRow.rowId)" [nzDisabled]="gridRow.disabled"
                class="{{index % 2 == 0 ? 'even-row' : ''}}" (nzCheckedChange)="onItemChecked(gridRow, $event)">
              </td>
              <td nzLeft class="{{index % 2 == 0 ? 'even-row' : ''}}">
                <div class="flex-center-container">
                  <ng-container *ngIf="!gridRow.isLoading">
                    <ng-container *ngIf="gridOption.defaultAction.edit">
                      <button *ngIf="!gridRow.editable" nz-button nzType="text" (click)="editRow(gridRow)">
                        <i class="icon-size16 primary-color" nz-icon nzType="edit" nzTheme="outline"></i>
                      </button>
                    </ng-container>
                    <button *ngIf="gridRow.editable" nz-button nzType="text" (click)="saveRow(gridRow,index)">
                      <i class="icon-size16 success-color" nz-icon nzType="save" nzTheme="outline"></i>
                    </button>
                    <button *ngIf="gridRow.editable" nz-button nzType="text" (click)="cancelSave(gridRow,index)">
                      <i class="icon-size16 error-color" nz-icon nzType="close" nzTheme="outline"></i>
                    </button>
                    <button *ngIf="gridOption.defaultAction.delete" (click)="onDelete(gridRow,index)" nz-button
                      nzType="text">
                      <i class="icon-size16 error-color" nz-icon nzType="delete" nzTheme="outline"></i>
                    </button>
                  </ng-container>
                  <ng-container *ngIf="gridRow.isLoading">
                    <span>
                      <i nz-icon class="icon-size16" [nzType]="'sync'" [nzSpin]="true"></i>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="gridOption.commands.length > 0">
                    <ng-container *ngFor="let command of gridOption.commands">
                      <button *ngIf="!isHidenCommand(command,gridRow);" nz-button nzType="text"
                        disabled="{{isDisableCommand(command,gridRow)}}"
                        (click)="!isDisableCommand(command,gridRow) && command.onClick && command.onClick(gridRow.item,gridRow)"><i
                          nz-tooltip [nzTooltipTitle]="command.tooltip" nz-icon
                          [style.color]="!isDisableCommand(command,gridRow) ? command.icon.color : ''"
                          [style.font-size]="command.icon.size || '16px'" nzType="{{command.icon.nzType}}"
                          [nzTheme]="command.icon.nzTheme || 'outline'"></i></button>
                    </ng-container>
                  </ng-container>
                </div>

              </td>
              <td nzEllipsis *ngFor="let colDef of colDefShows;" class="{{index % 2 == 0 ? 'even-row' : ''}}">
                <grid-col-date *ngIf="colDef.type == 'DATE'" [gridRow]="gridRow" [colDef]="colDef">
                </grid-col-date>
                <grid-col-number *ngIf="colDef.type == 'NUMBER'" [gridRow]="gridRow" [colDef]="colDef">
                </grid-col-number>
                <grid-col-text *ngIf="colDef.type == 'TEXT'" [gridRow]="gridRow" [colDef]="colDef">
                </grid-col-text>
                <grid-col-select *ngIf="colDef.type == 'SELECT'" [gridRow]="gridRow" [colDef]="colDef">
                </grid-col-select>
              </td>
            </tr>

          </ng-template>
        </tbody>
      </nz-table>
    </ng-template>

  </div>
  <div #footerGrid style="width: 100%;height: 80px;">
    <div class="grid-footer">
      <div>
        <button nz-button nzType="primary" (click)="reload()" nz-tooltip nzTooltipTitle="RELOAD"
          style="margin-right: 5px;"><i nz-icon nzType="reload" nzTheme="outline"></i></button>
        <button nz-button nzType="primary" (click)="openConfig()" nz-tooltip nzTooltipTitle="CONFIG GRID"
          style="margin-right: 5px;"><i nz-icon nzType="setting" nzTheme="outline"></i></button>

        <button nz-button nzType="primary" nz-tooltip nzTooltipTitle="EXPORT EXCEL" (click)="exportExcel()"
          style="margin-right: 5px;"><i nz-icon nzType="download" nzTheme="outline"></i></button>

      </div>
      <nz-pagination [(nzPageIndex)]="pageRequest.pageIndex" [nzTotal]="total" nzResponsive
        class="ant-table-pagination-right" nzShowSizeChanger nzShowQuickJumper
        (nzPageIndexChange)="pageIndexChange($event)" [nzPageSizeOptions]="pageSizeOptions"
        (nzPageSizeChange)="pageSizeChange($event)" [nzPageSize]="pageRequest.pageSize" [nzShowTotal]="totalTemplate">
      </nz-pagination>
      <ng-template #totalTemplate let-total>Total {{ total }} items</ng-template>
    </div>
  </div>
  <nz-drawer *ngIf="gridOption" [nzClosable]="false" [nzVisible]="visibleConfig" nzPlacement="right"
    nzTitle="Config Table" (nzOnClose)="closeConfig()">
    <ng-container *nzDrawerContent>
      <nz-table [nzShowPagination]="false" [nzData]="gridOption.columnDefs">
        <thead>
          <tr>
            <th nzLeft [nzChecked]="checkedConfig" [nzIndeterminate]="indeterminateConfig"
              (nzCheckedChange)="onAllCheckedConfig($event)"></th>
            <th>Title</th>
            <th>Field</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let colDef of gridOption.columnDefs">
            <td nzLeft [nzChecked]="!colDef.hiden" (nzCheckedChange)="onItemCheckedConfig(colDef, $event)"></td>
            <td>{{ colDef.headerName }}</td>
            <td>{{ colDef.field }}</td>
          </tr>
        </tbody>
      </nz-table>
    </ng-container>
  </nz-drawer>
</div>