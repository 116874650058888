import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { AuthModel, AuthService } from "~/app/services/auth";
import { UserService } from "~/app/services/business";
import { MessageService } from "~/app/services/common/message.service";


@Component({
  selector: "page-login",
  templateUrl: "./login.page.html",
  styleUrls: [
    "./login.page.less"
  ]
})
export class LoginPage {
  validateForm!: FormGroup;
  currentUser: AuthModel;
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }


    if (this.validateForm.valid) {
      this.login();
    }

  }

  constructor(private fb: FormBuilder,
    protected authService: AuthService,
    private messageService: MessageService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });
  }
  login() {
    this.userService.login({
      username: this.validateForm.controls['userName'].value,
      password: this.validateForm.controls['password'].value
    }).then((res) => {
      this.authService.currentUser = { username: res.username, role: res.role, lastName: res.lastname, firstName: res.firstname, departmentid: res.departmentid, companyid: res.companyid }
      localStorage.setItem('currentUser', JSON.stringify(this.authService.currentUser));
      this.router.navigate(['/main']);
    }).catch((err) => {
      this.messageService.handleError(err)
    });

  }

}