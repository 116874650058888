<ng-container *ngIf="colDef && gridRow">
  <div nz-tooltip nzTooltipTitle="{{gridRow.item[colDef.field!] | number}}">
    <ng-container *ngIf="gridRow.rowId; else addTempalte">
      <ng-container *ngIf="!isDisable; else disableTemplate">
        <ng-container *ngIf="!(colDef.editable || gridRow.editable)">
          {{gridRow.item[colDef.field!] | number}}
        </ng-container>
        <nz-input-number *ngIf="colDef.editable || gridRow.editable" [(ngModel)]="gridRow.item[colDef.field!]"
          style="width: 100%;" (ngModelChange)="onChangeColumn()" (keyup.Enter)="onEnterColumn()"></nz-input-number>
      </ng-container>
      <ng-template #disableTemplate>
        <ng-container>
          {{gridRow.item[colDef.field!] | number}}
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #addTempalte>
      <nz-input-number [(ngModel)]="gridRow.item[colDef.field!]" style="width: 100%;" (ngModelChange)="onChangeColumn()"
        (keyup.Enter)="onEnterColumn()"></nz-input-number>
    </ng-template>
  </div>
</ng-container>