const removeAccents = (str: string) => {
  const accentsMap = [
    "aàảãáạăằẳẵắặâầẩẫấậ",
    "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
    "dđ",
    "DĐ",
    "eèẻẽéẹêềểễếệ",
    "EÈẺẼÉẸÊỀỂỄẾỆ",
    "iìỉĩíị",
    "IÌỈĨÍỊ",
    "oòỏõóọôồổỗốộơờởỡớợ",
    "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
    "uùủũúụưừửữứự",
    "UÙỦŨÚỤƯỪỬỮỨỰ",
    "yỳỷỹýỵ",
    "YỲỶỸÝỴ"
  ];
  for (let i = 0; i < accentsMap.length; i++) {
    let re = new RegExp("[" + accentsMap[i].substr(1) + "]", "g");
    let char = accentsMap[i][0];
    str = str.replace(re, char);
  }
  return str;
};
const createInternalString = (text: string): string => {
  return removeAccents(text.trim().toLowerCase()).split(" ").filter(item => !!item).join("_");
}
const camelCaseToSnakeCase = (text: string): string => {
  return removeAccents(text.trim()).split(/(?=[A-Z])/).join('_').toLowerCase()
}
const snakeCaseToCamelCase = (text: string): string => {
  if (text.startsWith("_")) {
    text = text.substring(1)
  }
  return removeAccents(text.trim()).replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );
}

const upperCaseFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const roundNumber = (value) => {
  const neWvalue = Number(value) || 0;
  return parseFloat((Math.round(neWvalue * 100000) / 100000).toString());
}

const makeInQuery = (list: any[], keyInItem: string = "") => {
  if (keyInItem) {
    return "'" + list.map(e => e[keyInItem] ? e[keyInItem] : "").join("','") + "'";
  }
  return "'" + list.join("','") + "'";
}

const objToQueryString = (obj = {}) => {
  const keyValuePairs: string[] = [];
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return keyValuePairs.join('&');
}

function fixUrlPathName(pathName: string): string {
  const output = pathName.replace("//", "/");
  if (!pathName.includes("//")) {
    return output;
  } else {
    return fixUrlPathName(output)
  }
}

function fixUrl(url: string) {
  const fixUrl = new URL(url);
  const { origin, pathname } = fixUrl
  const newUrl = origin + fixUrlPathName(pathname);
  return newUrl;
}

export const StringUtils = {
  removeAccents,
  createInternalString,
  camelCaseToSnakeCase,
  snakeCaseToCamelCase,
  upperCaseFirstLetter,
  roundNumber,
  makeInQuery,
  objToQueryString,
  fixUrlPathName,
  fixUrl
}
