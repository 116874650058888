<ng-container>

  <div style="display: flex; justify-content: center;align-items: center; height: 100%;">
    <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()" style="flex: 1;">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your username!">
          <nz-input-group nzPrefixIcon="user">
            <input type="text" nz-input formControlName="userName" placeholder="Username" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Password!">
          <nz-input-group nzPrefixIcon="lock">
            <input type="password" nz-input formControlName="password" placeholder="Password" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Log in</button>
    </form>
  </div>

</ng-container>