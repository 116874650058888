import { Component, Input } from "@angular/core";




@Component({
  selector: "loading-view",
  templateUrl: "./loading.view.html",
  styleUrls: ["./loading.view.less"]
})
export class LoadingView {


  @Input()
  isLoading = false;
}
