import { Component, Input } from "@angular/core";
import { DateColDef, GridRow, TextColDef } from "../../models";



@Component({
  selector: "grid-col-text",
  templateUrl: "grid-col-text.component.html",
  styleUrls: ["grid-col-text.component.less"]
})
export class GridColTextComponent {


  @Input()
  gridRow: GridRow;

  @Input()
  colDef: TextColDef

  onChangeColumn() {
    if (this.colDef && this.colDef.onChange) {
      this.colDef.onChange(this.gridRow.item, this.gridRow)
    }
  }
  onEnterColumn() {
    if (this.colDef && this.colDef.onEnter) {
      this.colDef.onEnter(this.gridRow.item, this.gridRow)
    }
    this.gridRow.editable = false;
  }
  get isDisable() {
    if (!this.colDef.disabled) {
      return false;
    }
    if (typeof this.colDef.disabled === 'boolean') {
      return this.colDef.disabled;
    }
    return this.colDef.disabled(this.gridRow.item, this.gridRow);
  }
}
