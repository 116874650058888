import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayout } from './layouts/main/main.layout';
import { LoginPage } from './pages/login/login.page';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/business/user.service';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
    path: 'main', component: MainLayout, canActivate: [UserService], children: [
      { path: '', pathMatch: 'prefix', redirectTo: 'welcome' },
      { path: 'masterdata', loadChildren: () => import('./pages/masterdata/masterdata.module').then(m => m.MasterDataModule) },
    ]
  },

  {
    path: 'login', component: LoginPage
  },
];

// const routes: Routes = [
//   {
//     path: '', redirectTo: 'main', pathMatch: 'full'
//   },
//   {
//     path: 'main', component: MainComponent, canActivate: [UserService], children:[
//       {
//         path: 'authentication', loadChildren: './modules/authentication/authentication.module#AuthenticationModule'
//       },
//       {
//         path: 'system', loadChildren: './modules/system/system.module#SystemModule'
//       },
//       {
//         path: 'language', loadChildren: './modules/language/language.module#LanguageModule'
//       },
//       {
//         path: 'configuration', loadChildren: './modules/configuration/configuration.module#ConfigurationModule'
//       },
//       {
//         path: 'management', loadChildren: './modules/management/management.module#ManagementModule'
//       },
//       {
//         path: 'logger', loadChildren: './modules/logger/logger.module#LoggerModule'
//       },
//       {
//         path: 'billing', loadChildren: './modules/billing/billing.module#BillingModule'
//       },
//       {
//         path: 'api', loadChildren: './modules/api/api.module#ApiModule'
//       },
//       {
//         path: 'mobile-config', loadChildren: './modules/mobile-config/mobile-config.module#MobileConfigModule'
//       },
//       {
//         path: 'profile', component: ProfileComponent
//       }
//     ]
//   },
//   {
//     path: 'login', component: LoginComponent
//   },
//   {
//     path: '**', component: PageNotFoundComponent
//   }
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
