

import { IObjectPromise } from "~/@core/dto";
import { AxiosHttpClient, IAxiosRequestOptions } from "~/@core/network";
export abstract class BaseApiService {
  private httpClient: AxiosHttpClient
  constructor(config: {
    baseurl: string, options: IAxiosRequestOptions, interceptors?: IObjectPromise
  }) {
    this.httpClient = new AxiosHttpClient(config)
  }
  async get<T = any>(endpoint: string, params: any = {}, headers = {}): Promise<T> {
    try {
      const res = await this.httpClient.get<T>(endpoint, params, headers);
      return res.data
    } catch (error) {
      throw error;
    }
  }
  async getByBody<T = any>(endpoint: string, body: any = {}): Promise<T> {
    try {
      const res = await this.httpClient.getByBody<T>(endpoint, body);
      return res.data
    } catch (error) {
      throw error;
    }
  }
  async post<T = any>(endpoint: string, body: any = {}, headers = {}): Promise<T> {
    try {
      const res = await this.httpClient.post<T>(endpoint, body, headers);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async put<T = any>(endpoint: string, body: any = {}): Promise<T> {
    try {
      const res = await this.httpClient.put<T>(endpoint, body);
      return res.data
    } catch (error) {
      throw error;
    }
  }

}

