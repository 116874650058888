import { Injectable } from "@angular/core";
import { environment } from "~/environments/environment";
import { BaseApiService } from "./base-api.service";
import { KeyHeader } from "./key-header";



@Injectable({ providedIn: 'root' })
export class ApiService extends BaseApiService {
  constructor() {
    super({
      baseurl: environment.host,
      options: {
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json',
        }
      },
      interceptors: {
        token: async () => {
          return localStorage.getItem(KeyHeader.token) || ""
        },
        whseid: async () => {
          return localStorage.getItem(KeyHeader.whseid) || ""
        }
      }
    });
  }
}
